/* write your css here */
     @tailwind base;
     @tailwind components;
     @tailwind utilities;
     
     html,body{
        overflow-x: hidden;
     }

     .homePage_bg{
      background-image: url("./assets//Images/bghome.svg");
     }